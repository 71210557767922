import jsPDF from 'jspdf'
import printlayout from "./printlayout";

export default {
    printPayrollSlip(payrolldata,user){
        console.log('Printing Payroll Slip')
        var header = printlayout.header();
        var foot = printlayout.footer();

        var doc = new jsPDF('p', 'mm', 'legal');
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();

        doc.addImage(header, 'JPEG', 0, 0, width, 32)

        var pos_y = 32;
        doc.setFontSize(12)
        doc.setFontType('bold')
        doc.setTextColor(90, 181, 94);
        printlayout.centerTextfunction(doc,""+payrolldata.title,pos_y+=10)

        doc.setTextColor(0,0,0);
        doc.setFontType('normal')
        doc.setFontSize(10)
        doc.setFontStyle('Roman')

        var name = pos_y+=8
        doc.text(10,name, 'Name')
        doc.text(31,name, ':')
        doc.text(35,name, user.firstname+' '+user.middlename+' '+user.lastname)

        doc.text(110,name, 'Position')
        doc.text(125,name, ':')
        doc.text(128,name, payrolldata.position != null || payrolldata.position == true ? payrolldata.position : 'not available')

        var empId = pos_y+=4;
        doc.text(10,empId, 'Employee Id:')
        doc.text(31,empId, ':')
        doc.text(35,empId, user.employee_id)
        doc.setFontType('bold')
        var thead_y = pos_y+=5
        var inner_box_height = 80

        var box_foot = thead_y+inner_box_height+6;

        var content_y = pos_y+=10
        var com_linner_box_text_y = content_y
        var com_rnner_box_amount_y = content_y
        var com_linner_box_text_x = 13
        var com_rnner_box_amount_x = 90
        var p_space = 4

        var deduc_linner_box_text_y = content_y
        var deduc_rnner_box_amount_y = content_y
        var deduc_linner_box_text_x = 111.5
        var deduc_rnner_box_amount_x = 202

        doc.rect(10, thead_y, 97.5, 6)
        doc.text(13,thead_y+4, 'Compensations')
        //
        doc.rect(107.5,thead_y, 97.5, 6)
        doc.text(deduc_linner_box_text_x,thead_y+4, 'Deductions')
        //
        doc.setFontType('normal')
        var content_box_width = 97.5
        // // COMPENSATIONS
        doc.rect(10, thead_y+6, content_box_width, inner_box_height)
        doc.text(com_linner_box_text_x,com_linner_box_text_y, 'Basic Salary')
        doc.text(com_linner_box_text_x,com_linner_box_text_y+=p_space, 'PERA')
        doc.text(com_linner_box_text_x,com_linner_box_text_y+=p_space, 'Subs and Laundry Allowance')
        doc.text(com_linner_box_text_x,com_linner_box_text_y+=p_space, 'Adjustment')

        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.salaries_wages_regular),com_rnner_box_amount_x+15,com_rnner_box_amount_y)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.pera),com_rnner_box_amount_x+15,com_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.subsistence_laundry_allowance),com_rnner_box_amount_x+15,com_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.adjustment),com_rnner_box_amount_x+15,com_rnner_box_amount_y+=p_space)

        // // DEDUCTIONS
        doc.rect(107.5, thead_y+6, content_box_width, inner_box_height)
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y, 'Withholding Tax')
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y+=p_space, 'GSIS')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Consolidated Loan')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Policy Loan Regular')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Policy Loan Optional')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Life and Retirement Insurance Premium')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Unli Optional Life Insurance')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Calamity / E. Loan')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Education Assistance')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Multi-purpose Loan')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Computer Loan')
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y+=p_space, 'SSS')
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y+=p_space, 'HMDF')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Pag-ibig Premium')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Multi Purpose Loan')
        doc.text(deduc_linner_box_text_x+5,deduc_linner_box_text_y+=p_space, 'Housing')
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y+=p_space, 'PHILHEALTH')
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y+=p_space, 'Leave without Pay')
        doc.text(deduc_linner_box_text_x,deduc_linner_box_text_y+=p_space, 'Landbank Salary Loan')

        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.withholding_tax),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y)
        doc.text(deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space, '')
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.consolidated_loan),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.policy_loan_regular),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.policy_loan_optional),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.life_retirement),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.unlimited_optional_life_insurance),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.emergency_calamity_loan),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.educational_assitance),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.multipurpose_loan),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.computer_loan),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.sss),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        doc.text(deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space, '')
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.premium),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.multipurpose_calamity_loan),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.housing),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.philhealth),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.leave_without_pay),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.landbank_salary_loan),deduc_rnner_box_amount_x,deduc_rnner_box_amount_y+=p_space)

        doc.setFontType('bold')
        doc.rect(10, box_foot, 97.5, 6)
        doc.text(com_linner_box_text_x,box_foot+4, 'Gross Amount Earned')
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.gross_ammount_earned),com_rnner_box_amount_x+15,box_foot+4)

        doc.rect(107.5, box_foot, 97.5, 6)
        doc.text(deduc_linner_box_text_x,box_foot+4, 'Total Deductions')
        printlayout.rightTextfunction(doc,printlayout.formatPrice(payrolldata.total_deduction),deduc_rnner_box_amount_x,box_foot+4)

        doc.rect(10, box_foot+6, 195, 6)
        printlayout.rightTextfunction(doc,'NET PAY               '+printlayout.formatPrice(payrolldata.gross_ammount_earned-payrolldata.total_deduction),
            deduc_rnner_box_amount_x,box_foot+10)

        doc.setFontType('normal')
        doc.text(14,box_foot+24, '___________________________')
        doc.text(28,box_foot+28, "Accountant")


        doc.text(deduc_linner_box_text_x+40,box_foot+24, '___________________________')
        doc.text(deduc_linner_box_text_x+50,box_foot+28, "Employee's Signature")

        doc.setFontType('italic')
        doc.text(10,box_foot+17, "Certified Correct:")

        var halfPage = height/2;
        var currentDateWithFormat = new Date().toJSON().slice(0,10).replace(/-/g,' - ');
        doc.setFontType('italic')
        doc.setFontSize(8)
        printlayout. rightTextfunction(doc,'',width-5,halfPage+1)
        printlayout.centerTextfunction(doc,'This is computer generated Payslip and does require signature to ensure it\'s validity. Date Printed: '+currentDateWithFormat,halfPage+1)
        doc.addImage(foot, 'JPEG', 0,halfPage +2, width, 12)

       printlayout.dottedLine(doc,2,halfPage+15,width-2,halfPage+15,2)
        window.open(doc.output('bloburl'), '_blank');

    },
}